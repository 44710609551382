import React from "react"
import ExpenseAccount from "../../../components/body/pages/en-ng/business/expense-account"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const ExpenseAccountsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/expense-accounts/"}
      title="Expense Accounts | Streamline Business Finances | Kuda"
      description="Manage finances with Kuda Business Expense Accounts. Create spaces for specific expenses in your main account. Simplify budgeting with unique account numbers."
    />

    <ExpenseAccount />
  </Layout>
)

export default ExpenseAccountsPage
